.dashboard-links {
  padding: 24px 32px;
  border-bottom: 1px solid #DEE0E5;
}

.dashboard-link-card {
  box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  border-radius: 12px;
}

.dashboard-link-card .ant-card-body {
  display: flex;
  align-items: center;
}

.dashboard-link-name {
  font-size: 16px;
  line-height: 24px;
  color: #4F67FF;
  margin-left: 16px;
}