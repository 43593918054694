.client-project-card {
  width: 232px;
  height: 116px;
  margin-bottom: 16px;
  border-radius: 16px 0px 16px 16px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.client-project-card--active {
  background: white;
  border: 1px solid white;
  cursor: initial;
}

.client-project-card > .ant-card-body {
  padding: 16px;
}

.client-project-card h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.client-project-stage {
  font-size: 14px;
  line-height: 22px;
  color: #4D5465;
}

.client-project-phase {
  font-size: 16px;
  line-height: 24px;
  color: #212236;
}