.project-name {
  font-size: 24px;
  line-height: 32px;
  color: #121212;
  padding: 24px 32px;
  border-bottom: 1px solid #DEE0E5;
}

.project-tabs .ant-tabs-nav {
  display: flex;
}

.project-tabs .ant-tabs-nav-list {
  width: 100%;
}

.project-tabs .ant-tabs-tab {
  margin-left:0;
  flex-grow: 1;
  width: 100%;
}

.project-tabs .ant-tabs-tab .ant-tabs-tab-active {
  color: #657087;
}

.project-tabs .ant-tabs-tab .ant-tabs-tab-active {
  color: #4F67FF;
}

/* active border color */
.ant-tabs-ink-bar { 
  background: #6777F8;
}

.project-tabs .ant-tabs-tab-btn {
  text-align: center;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  
}