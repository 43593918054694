.selected-item {
  background: #4F67FF !important;
  padding: 8px !important;
  border: none !important;

  .adv-project-subtitle {
    color: #FFFFFF !important;
  }
}

#dashboard-sidebar {
  position: relative;
}

.dashboard-container {
  height: 100%;
}

#dashboard-main-container {
  padding: 24px;
  background: #ECEFF3 !important;
}

.sidebar {
  overflow-y: auto;

}

.project-item {
  padding: 8px;
  border-radius: 8px;
}


.project-item-adv {
  padding: 7px;
  border: 1px solid #D9DCE3;
  margin-bottom: 8px;
}

.filter-projects-button:hover>.filter-projects-container {
  left: 0;
  opacity: 1;
  pointer-events: all;
}

.select-filter {

  .ant-select-selector {
    height: 32px !important;
    align-items: center;
  }
}

.create-projects-button {
  cursor: pointer;
  padding: 10px 0;
  margin-left: 8px;
  box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  border-radius: 100%;
  font-size: 14px;
  transition: all .3s ease-in-out;

  background: #4F67FF;

  .plus-icon {
    color: white !important;
  }
}


.ant-dropdown-menu {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.create-projects-button:hover {
  background: #2845ff;

  .plus-icon {
    color: white !important;
  }
}

.filter-projects-button {
  cursor: pointer;
  padding: 10px 0;
  margin-left: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  border-radius: 6px;
  color: #4D546E;
  font-size: 14px;
  transition: all .3s ease-in-out;
}

.filter-reset-all {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
  color: #4F67FF;
  margin-bottom: 16px;
}

.filter-button-active {
  border: 1px solid #4F67FF;

  .filter-icon {
    color: #4F67FF;
  }
}

.filter-projects-button:hover {
  position: absolute;
  width: 96%;
  right: 2%;
  padding: 0 !important;
  margin: 0;
  color: #4F67FF;
  border: 1px solid #4F67FF;

  .filter-text::before {
    content: "Filter";
    margin-left: .5rem;
  }
}

#dashboard-sidebar {
  border-right: 1px solid rgba(0, 0, 0, .12);
}

.search-filter-input {

  input {
    background: #ECEFF3;
  }
}

.ant-tabs-tab .anticon {
  margin-right: 0;
}

.filter-projects-container {
  left: -25px;
  pointer-events: none;
  opacity: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(53, 53, 65, 0.16), 0px 4px 8px rgba(53, 53, 65, 0.12);
  padding: 12px 12px 16px 12px;
  color: #1D1D1D !important;
  transition: all .3s ease-in-out;

  .ant-switch-handle {
    top: 3px;
    left: 3px;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 21px);
  }

  select {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  select {
    background-image: url("../../static/images/icon-select.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
  }
}