#project-by-id-container {
  height: 100%;

  .ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 3.5px;
  }

  .ant-page-header-heading-sub-title {
    font-family: Proxima Nova;
    font-weight: 600;
    font-size: 16px;
    color: rgba(29, 29, 29, 0.5);
  }

  .ant-tabs-nav-list {
    padding: 6px 13px 6px 13px;
  }

  .ant-layout {
    border-radius: 8px;
  }

  .padding-sides {
    padding: 0 8px !important;
  }

  .ant-tabs-ink-bar {
    display: block !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4F67FF !important;
    opacity: 1;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn>a {
    color: #4F67FF !important;
    opacity: 1;
  }

  .ant-tabs-tab-active {
    background: none !important;
  }

  .ant-divider {
    background: none !important;
  }

  .ant-page-header {
    background: #ECEFF3 !important;
    padding: 0 !important;
    margin-bottom: 8px;
  }

  .ant-tabs-nav {
    overflow: visible !important;
  }

  .ant-tabs-content-holder {
    overflow-x: clip;
    overflow-y: auto;
  }

  .custom-container {
    padding: 0 !important;
  }

  .ant-tabs-content-left {
    background: none !important;
  }
}