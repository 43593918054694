.client-layout {
  background: url("../../images/client-bg.png") left center / cover;
  height: 100vh;
}

.client-sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  background-color: transparent;
  z-index: 1;
}

.client-sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.client-sidebar-logo {
  margin-bottom: 24px;
}

.client-sidebar-name {
  font-size: 18px;
  line-height: 18px;
  margin: 24px 0 24px 0;
  padding: 0;
  color: #121212;
}

.client-content-layout {
  margin-left: 280px;
  background-color: transparent;
}

.client-content {
  background-color: white;
  border-radius: 24px 0px 0px 24px;
}